export default {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    cdn: process.env.REACT_APP_API_CDN,
  },
  spinnerTimeout: process.env.REACT_APP_SPINNER_TIMEOUT,
  axios: {
    defaultTimeout: process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
  },
};
