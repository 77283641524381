import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_PROJECT: '/projects/single/:id',
  GET_PROJECTS: '/projects',
  DOWNLOAD_ZIP: '/projects/export-zip/:projectId',
  DOWNLOAD_EXCEL: '/projects/export-excel/:projectId',
};

class ProjectService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getProject = (projectId) => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECT.replace(':id', projectId),
      method: HTTP_METHODS.GET,
    });
  };

  getProjects = () => {
    return this.httpService.request({
      url: ROUTES.GET_PROJECTS,
      method: HTTP_METHODS.GET,
    });
  };

  downloadZip = (projectId) => {
    return this.httpService.request({
      url: ROUTES.DOWNLOAD_ZIP.replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
      responseType: 'arraybuffer',
    });
  };

  downloadExcel = (projectId) => {
    return this.httpService.request({
      url: ROUTES.DOWNLOAD_EXCEL.replace(':projectId', projectId),
      method: HTTP_METHODS.GET,
    });
  };
}

const projectService = new ProjectService(httpService);

export default projectService;
