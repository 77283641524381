import React from 'react';
import styles from './Footer.module.scss';
import Container from '@mui/material/Container';
import FooterNavigation from './FooterNavigation';
import FooterSocials from './FooterSocials';
import logo from '../../assets/images/logo/logo.svg';

const Footer = () => {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.innerWrapper}>
          <div className={styles.upperWrapper}>
            <img src={logo} alt="profile" className={styles.image} />
            <FooterSocials />
          </div>
          <div className={styles.lowerWrapper}>
            <span className={styles.copyrightText}>
              @{new Date().getFullYear()} Lumanity Inc.
            </span>
            <FooterNavigation />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
