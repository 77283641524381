import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import PublicRoute from 'containers/PublicRoute';
import UserProfilePage from 'containers/UserProfilePage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import RespondentsListPage from 'containers/RespondentsListPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import ProjectListPage from 'containers/ProjectListPage/Loadable';
import {
  DASHBOARD,
  LOGIN,
  USER_PROFILE,
  PROJECT_LIST,
  SINGLE_PROJECT,
} from 'routes';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path={DASHBOARD}>
        <Redirect to={PROJECT_LIST} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={SINGLE_PROJECT}
        component={RespondentsListPage}
      />
      <PrivateRoute exact path={USER_PROFILE} component={UserProfilePage} />
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      <PrivateRoute exact path={PROJECT_LIST} component={ProjectListPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
