import React from 'react';
import styles from './Footer.module.scss';
import { ReactComponent as LinkedInIcon } from '../../assets/images/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter.svg';

const FooterSocials = () => {
  const socials = [
    {
      id: 1,
      icon: <LinkedInIcon className={styles.icon} />,
      link: 'https://www.linkedin.com',
    },
    {
      id: 2,
      icon: <TwitterIcon className={styles.icon} />,
      link: 'https://www.twitter.com',
    },
  ];

  const Item = ({ item, index }) => (
    <a
      href={item.link}
      style={{ marginRight: index === socials.length - 1 ? 0 : 32 }}
      key={item.id}
    >
      {item.icon}
    </a>
  );

  return (
    <div className={styles.socialsWrapper}>
      {socials.map((item, index) => (
        <Item item={item} index={index} key={item.id} />
      ))}
    </div>
  );
};

export default FooterSocials;
