import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en.json';
import translationEs from './locales/es.json';
import translationFr from './locales/fr.json';
import translationDe from './locales/de.json';
import translationIt from './locales/it.json';
import translationMan from './locales/man.json';
import translationJa from './locales/ja.json';
import translationPor from './locales/por.json';
import { LANGUAGE_ABBREVIATIONS } from '../consts/languages';

const LOCALE_ENG = 'en';
const LOCALE_DE = 'de';

export const LOCALES = [LOCALE_ENG, LOCALE_DE];

export const DEFAULT_LOCALE = LOCALE_ENG;

const resources = {
  [LANGUAGE_ABBREVIATIONS.ENGLISH]: {
    translation: translationEn,
  },
  [LANGUAGE_ABBREVIATIONS.SPANISH]: {
    translation: translationEs,
  },
  [LANGUAGE_ABBREVIATIONS.FRENCH]: {
    translation: translationFr,
  },
  [LANGUAGE_ABBREVIATIONS.GERMAN]: {
    translation: translationDe,
  },
  [LANGUAGE_ABBREVIATIONS.ITALIAN]: {
    translation: translationIt,
  },
  [LANGUAGE_ABBREVIATIONS.MANDARIN]: {
    translation: translationMan,
  },
  [LANGUAGE_ABBREVIATIONS.JAPANESE]: {
    translation: translationJa,
  },
  [LANGUAGE_ABBREVIATIONS.PORTUGUESE]: {
    translation: translationPor,
  },
};

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('language') || DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  debug:
    process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export const $t = (key, params = {}) => {
  return i18n.t(key, params);
};

export default i18n;
