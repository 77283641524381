import { $t } from '../../translations';
import { LANGUAGE_ABBREVIATIONS } from '../../consts/languages';

export const languages = () => {
  return [
    {
      id: 1,
      value: LANGUAGE_ABBREVIATIONS.ENGLISH,
      label: $t('app_bar.languages.english'),
    },
    {
      id: 2,
      value: LANGUAGE_ABBREVIATIONS.SPANISH,
      label: $t('app_bar.languages.spanish'),
    },
    {
      id: 3,
      value: LANGUAGE_ABBREVIATIONS.FRENCH,
      label: $t('app_bar.languages.french'),
    },
    {
      id: 4,
      value: LANGUAGE_ABBREVIATIONS.GERMAN,
      label: $t('app_bar.languages.german'),
    },
    {
      id: 5,
      value: LANGUAGE_ABBREVIATIONS.ITALIAN,
      label: $t('app_bar.languages.italian'),
    },
    {
      id: 6,
      value: LANGUAGE_ABBREVIATIONS.MANDARIN,
      label: $t('app_bar.languages.mandarin'),
    },
    {
      id: 7,
      value: LANGUAGE_ABBREVIATIONS.JAPANESE,
      label: $t('app_bar.languages.japanese'),
    },
    {
      id: 8,
      value: LANGUAGE_ABBREVIATIONS.PORTUGUESE,
      label: $t('app_bar.languages.portuguese'),
    },
  ];
};
