import {
  CREATE_RESPONDENT_SAGA,
  GET_RESPONDENT_SAGA,
  SET_RESPONDENT_DATA,
  OPEN_GENERATE_RESPONDENT_MODAL,
  OPEN_RESPONDENT_MODAL,
} from './actionTypes';

export function createRespondentSaga(projectId) {
  return {
    type: CREATE_RESPONDENT_SAGA,
    projectId,
  };
}

export function setRespondentData(payload) {
  return {
    type: SET_RESPONDENT_DATA,
    payload,
  };
}

export function getRespondentSaga(respondentId) {
  return {
    type: GET_RESPONDENT_SAGA,
    respondentId,
  };
}

export function openGenerateRespondentModal(payload) {
  return {
    type: OPEN_GENERATE_RESPONDENT_MODAL,
    payload,
  };
}

export function setOpenRespondentModal(payload) {
  return {
    type: OPEN_RESPONDENT_MODAL,
    payload,
  };
}
