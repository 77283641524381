export const LANGUAGE_ABBREVIATIONS = {
  ENGLISH: 'en',
  SPANISH: 'es',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  MANDARIN: 'man',
  JAPANESE: 'ja',
  PORTUGUESE: 'por',
};
