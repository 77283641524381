import { call, takeLatest, put } from 'redux-saga/effects';
import { setProjects } from './actions';
import projectService from '../../services/ProjectService';
import { GET_PROJECTS_SAGA } from './actionTypes';

export function* getProjectsSaga() {
  try {
    const projects = yield call(projectService.getProjects);
    yield put(setProjects(projects));
  } catch (error) {
    console.error(error);
  }
}

export default function* projectSaga() {
  yield takeLatest(GET_PROJECTS_SAGA, getProjectsSaga);
}
