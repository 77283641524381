import { GET_PROJECTS_SAGA, SET_PROJECTS } from './actionTypes';

export function getProjectsSaga() {
  return {
    type: GET_PROJECTS_SAGA,
  };
}

export function setProjects(payload) {
  return {
    type: SET_PROJECTS,
    payload,
  };
}
