import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import Routes from './Routes';
import { fetchAuthenticatedUser, logout } from 'store/auth/actions';
import { makeSelectToken, makeSelectUser } from 'store/auth/selectors';
import AppBar from 'components/AppBar';

import Footer from '../../components/Footer';
import styles from './App.module.scss';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../routes';
import SnackbarComponent from '../../components/Snackbar/SnackbarComponent';
import Loader from '../Loader';

function App() {
  const dispatch = useDispatch();

  const history = useHistory();
  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const handleLanguageChange = useCallback((val) => {
    console.log('LANGUAGE CHANGED');
    i18n.changeLanguage(val);
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(fetchAuthenticatedUser());
    } else {
      history.push(LOGIN);
    }
  }, [token, dispatch]);

  const renderLoadingIndicator = <div>Loading...</div>;

  return (
    <HelmetProvider>
      <SnackbarProvider>
        <Helmet>
          <title>Lumanity Recruiter</title>
        </Helmet>
        {token && !user ? (
          renderLoadingIndicator
        ) : (
          <>
            {user && (
              <AppBar
                onLanguageChange={handleLanguageChange}
                user={user}
                onLogout={handleLogout}
              />
            )}
            <div className={styles.container}>
              <Routes />
              <Loader />
            </div>
            {user && <Footer />}
          </>
        )}
        <SnackbarComponent />
      </SnackbarProvider>
    </HelmetProvider>
  );
}

export default App;
