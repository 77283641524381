import httpService from './HttpService';
import { HTTP_METHODS } from 'consts';

const ROUTES = {
  GET_RESPONDENT_GRID_BY_PROJECT_ID:
    '/respondents/by-project/:projectId/grid/:page/:limit/',
  CREATE_RESPONDENT: '/respondents',
  GET_RESPONDENT: 'respondents/:respondentId',
  DELETE_RESPONDENT: 'respondents/:respondentId',
  GENERATE_RESPONDENT: 'respondents/generate',
};

class RespondentService {
  constructor(httpService) {
    this.httpService = httpService;
  }

  getRespondentGridByProjectId = (projectId, page, limit, order, data) => {
    return this.httpService.request({
      url:
        ROUTES.GET_RESPONDENT_GRID_BY_PROJECT_ID.replace(
          ':projectId',
          projectId
        )
          .replace(':page', page)
          .replace(':limit', limit) +
        (order ? order.type + '/' + order.order : ''),
      method: HTTP_METHODS.POST,
      data,
    });
  };

  createRespondent = (data) => {
    return this.httpService.request({
      url: ROUTES.CREATE_RESPONDENT,
      method: HTTP_METHODS.POST,
      data,
    });
  };

  getRespondent = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.GET_RESPONDENT.replace(':respondentId', respondentId),
      method: HTTP_METHODS.GET,
    });
  };

  deleteRespondent = (respondentId) => {
    return this.httpService.request({
      url: ROUTES.DELETE_RESPONDENT.replace(':respondentId', respondentId),
      method: HTTP_METHODS.DELETE,
    });
  };

  generateRespondent = (data) => {
    return this.httpService.request({
      url: ROUTES.GENERATE_RESPONDENT,
      method: HTTP_METHODS.POST,
      responseType: 'arraybuffer',
      data,
    });
  };
}

const respondentService = new RespondentService(httpService);

export default respondentService;
