import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import { PROJECT_LIST } from '../../routes';

const FooterNavigation = () => {
  const links = [
    { id: 1, name: 'Sitemap', link: '' },
    { id: 2, name: 'Terms of Use', link: '' },
    {
      id: 3,
      name: 'Privacy Policy',
      link: 'https://lumanity.com/privacy-policy/',
    },
    { id: 4, name: 'Cookie Policy', link: '' },
  ];

  const Item = ({ item, index }) => (
    <li key={index}>
      <Link
        to={{ pathname: item.link }}
        target={item.link && '_blank'}
        className={styles.link}
        style={{ paddingRight: index === links.length - 1 ? 0 : 12 }}
      >
        {item.name}
      </Link>
    </li>
  );

  return (
    <ul className={styles.navigation}>
      {links.map((item, index) => (
        <Item item={item} index={index} key={item.id} />
      ))}
    </ul>
  );
};

export default FooterNavigation;
