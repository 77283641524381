import produce from 'immer';
import { SET_PROJECTS } from './actionTypes';

export const initialState = {
  projects: null,
};

/* eslint-disable default-case */
const projectsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_PROJECTS:
        draft.projects = payload;
        break;
    }
  });

export default projectsReducer;
