import { takeLatest, call, put } from 'redux-saga/effects';
import { CREATE_RESPONDENT_SAGA, GET_RESPONDENT_SAGA } from './actionTypes';
import respondentService from '../../services/RespondentService';
import { setOpenRespondentModal, setRespondentData } from './actions';

export function* createRespondentSaga({ projectId }) {
  try {
    const data = {
      project_id: projectId,
    };

    const createRespondentData = yield call(
      respondentService.createRespondent,
      data
    );
    yield put(setRespondentData(createRespondentData));
    yield put(setOpenRespondentModal(true));
  } catch (error) {
  } finally {
  }
}

export function* getRespondentSaga({ respondentId }) {
  try {
    const createRespondentData = yield call(
      respondentService.getRespondent,
      respondentId
    );
    yield put(setRespondentData(createRespondentData));
    yield put(setOpenRespondentModal(true));
  } catch (error) {
  } finally {
  }
}

export default function* respondentSaga() {
  yield takeLatest(CREATE_RESPONDENT_SAGA, createRespondentSaga);
  yield takeLatest(GET_RESPONDENT_SAGA, getRespondentSaga);
}
