import { fork } from 'redux-saga/effects';

import AuthSagas from './auth/sagas';
import ProfileSagas from './profile/sagas';
import RespondentSagas from './respondent/sagas';
import ProjectSagas from './project/sagas';

export default function* rootSaga() {
  yield fork(AuthSagas);
  yield fork(ProfileSagas);
  yield fork(RespondentSagas);
  yield fork(ProjectSagas);
}
