import produce from 'immer';
import {
  OPEN_GENERATE_RESPONDENT_MODAL,
  OPEN_RESPONDENT_MODAL,
  SET_RESPONDENT_DATA,
} from './actionTypes';

export const initialState = {
  respondentData: null,
  generateRespondentModalOpen: false,
  respondentModalOpen: false,
};

/* eslint-disable default-case */
const respondentReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_RESPONDENT_DATA:
        draft.respondentData = payload;
        break;
      case OPEN_GENERATE_RESPONDENT_MODAL:
        draft.generateRespondentModalOpen = payload;
        break;
      case OPEN_RESPONDENT_MODAL:
        draft.respondentModalOpen = payload;
        break;
    }
  });

export default respondentReducer;
